// stylelint-disable selector-max-compound-selectors,scale-unlimited/declaration-strict-value

@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/radii';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../hotels-utils/mixins/breakpoints.scss' as breakpoints2;

.ProviderLogos {
  &__header {
    margin-bottom: tokens.bpk-spacing-lg();
  }

  .logos {
    display: grid;
    grid-template-columns: repeat(auto-fit, 7.5rem);
    grid-gap: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-mobile {
      grid-template-columns: repeat(auto-fit, 6rem);
      grid-gap: tokens.bpk-spacing-base();
    }
  }

  &__providerLogo {
    width: 120 * tokens.$bpk-one-pixel-rem;
    height: 60 * tokens.$bpk-one-pixel-rem;
    overflow: hidden;

    @include radii.bpk-border-radius-sm;

    @include breakpoints.bpk-breakpoint-mobile {
      width: 96 * tokens.$bpk-one-pixel-rem;
      height: 48 * tokens.$bpk-one-pixel-rem;
    }
  }

  &__providerLogoShadow {
    box-shadow: tokens.$bpk-box-shadow-sm;
  }

  &__providerLogoLink {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: tokens.$bpk-text-primary-day;
    text-align: center;
    text-decoration: none;

    &:active,
    &:visited,
    &:hover,
    &:hover:not(:active, :disabled) {
      color: tokens.$bpk-text-primary-day;
    }

    &:hover {
      box-shadow: tokens.$bpk-box-shadow-lg;
    }
  }

  &__providerLogoImage {
    width: 100%;
    height: 100%;

    div[class*='bpk-image--'] {
      background-color: initial;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include breakpoints.bpk-breakpoint-mobile {
        object-fit: contain;
      }
    }
  }
}
